export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const FORGOT_SUCCESS = "FORGOT_SUCCESS";
export const VERIFY_OTP = "VERIFY_OTP";
export const RESET_SUCCESS = "RESET_SUCCESS";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

// FAQ TYPES
export const ADD_FAQ = "ADD_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";
export const EDIT_FAQ = "EDIT_FAQ";

// LANDING PAGE UPDATE TYPES
export const LANDING_PAGE_UPDATE = "LANDING_PAGE_UPDATE";
export const GET_ALL_TERMS_DATA = "GET_ALL_TERMS_DATA";
export const UPDATE_TERMS = "UPDATE_TERMS";
export const NEW_LANDING_DATA = "NEW_LANDING_DATA";
export const UPDATE_MISSION = "UPDATE_MISSION";
export const UPDATE_VIDEO = "UPDATE_VIDEO";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPDATE_WHY_DATA = "UPDATE_WHY_DATA";
export const ADD_SERVICE_DATA = "ADD_SERVICE_DATA";
export const UPDATE_SERVICE_DATA = "UPDATE_SERVICE_DATA";
export const DELETE_SERVICE_DATA = "DELETE_SERVICE_DATA";

// CONTACT_LIST TYPES
export const CONTACT_LIST = "CONTACT_LIST";
export const ADMIN_CONTACT_TO_USER = "ADMIN_CONTACT_TO_USER";

// USER LIST TYPES
export const GET_USER_LIST = "GET_USER_LIST";