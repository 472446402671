import React, { lazy, Suspense } from 'react';
import WelcomeScreen from './components/WelcomeScreen';
const Routes = lazy(() => import('./routes/route'));

const App = () => {
  return (
    <>
      <Suspense fallback={<WelcomeScreen />}>
        <Routes />
      </Suspense>
    </>
  )
}

export default App
