import React from 'react';
import a1trucklogo from '../assets/a1trucklogo.png';
import './main.css';


const WelcomeScreen = () => {
    return (
        <>
            <div className='welcome_screen'>
                <img src={a1trucklogo} alt="logo" className='welcome_img_width' />
            </div>
        </>
    )
}

export default WelcomeScreen
