import * as actions from '../types';

const initialState = {
    token: '',
    isLoggedIn: false,
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case actions.LOGIN_SUCCESS: return {
            ...state,
            ...payload,
            isLoggedIn: true
        }

        case actions.FORGOT_SUCCESS: return {
            ...state,
            ...payload,
            isLoggedIn: true,
            type: "forgot"
        }

        case actions.VERIFY_OTP: return {
            ...state,
            ...payload,
            isLoggedIn: true,
            type: "verify"
        }

        default: return state
    }
} 